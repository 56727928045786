define("discourse/plugins/chat/discourse/components/chat/navbar/title", ["exports", "@ember/helper", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse-common/helpers/d-icon", "discourse/plugins/chat/discourse/components/chat/navbar/sub-title", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _helper, _dButton, _concatClass, _dIcon, _subTitle, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ChatNavbarTitle = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div
      title={{@title}}
      class={{concatClass "c-navbar__title" (if @showFullTitle "full-title")}}
    >
      {{#if @openThreadTitleModal}}
        <DButton
          class="c-navbar__title-text btn-transparent"
          @icon={{@icon}}
          @action={{@openThreadTitleModal}}
          @translatedLabel={{@title}}
        />
      {{else}}
        <span class="c-navbar__title-text">
          {{if @icon (icon @icon)}}
          {{@title}}
        </span>
      {{/if}}
      {{#if (has-block)}}
        {{yield (hash SubTitle=SubTitle)}}
      {{/if}}
    </div>
  
  */
  {
    "id": "l6Trsi/y",
    "block": "[[[1,\"\\n  \"],[10,0],[15,\"title\",[30,1]],[15,0,[28,[32,0],[\"c-navbar__title\",[52,[30,2],\"full-title\"]],null]],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"      \"],[8,[32,1],[[24,0,\"c-navbar__title-text btn-transparent\"]],[[\"@icon\",\"@action\",\"@translatedLabel\"],[[30,4],[30,3],[30,1]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,1],[14,0,\"c-navbar__title-text\"],[12],[1,\"\\n        \"],[1,[52,[30,4],[28,[32,2],[[30,4]],null]]],[1,\"\\n        \"],[1,[30,1]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[41,[48,[30,5]],[[[1,\"      \"],[18,5,[[28,[32,3],null,[[\"SubTitle\"],[[32,4]]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[\"@title\",\"@showFullTitle\",\"@openThreadTitleModal\",\"@icon\",\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/title.js",
    "scope": () => [_concatClass.default, _dButton.default, _dIcon.default, _helper.hash, _subTitle.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = ChatNavbarTitle;
});