define("discourse/plugins/chat/discourse/components/chat-side-panel-resizer", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ChatSidePanelResizer = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="chat-side-panel-resizer"></div>
  
  */
  {
    "id": "7AI0ZdJe",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"chat-side-panel-resizer\"],[12],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-side-panel-resizer.js",
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = ChatSidePanelResizer;
});