define("discourse/plugins/chat/discourse/components/channels-list", ["exports", "@glimmer/component", "@ember/service", "discourse-common/helpers/i18n", "discourse/plugins/chat/discourse/components/channels-list-direct", "discourse/plugins/chat/discourse/components/channels-list-public", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _i18n, _channelsListDirect, _channelsListPublic, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChannelsList extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          role="region"
          aria-label={{i18n "chat.aria_roles.channels_list"}}
          class="channels-list"
        >
          <ChannelsListPublic />
    
          {{#if this.chat.userCanAccessDirectMessages}}
            <ChannelsListDirect />
          {{/if}}
        </div>
      
    */
    {
      "id": "f5wA2cjQ",
      "block": "[[[1,\"\\n    \"],[10,0],[14,\"role\",\"region\"],[15,\"aria-label\",[28,[32,0],[\"chat.aria_roles.channels_list\"],null]],[14,0,\"channels-list\"],[12],[1,\"\\n      \"],[8,[32,1],null,null,null],[1,\"\\n\\n\"],[41,[30,0,[\"chat\",\"userCanAccessDirectMessages\"]],[[[1,\"        \"],[8,[32,2],null,null,null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/channels-list.js",
      "scope": () => [_i18n.default, _channelsListPublic.default, _channelsListDirect.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChannelsList;
});