define("discourse/plugins/chat/discourse/components/chat/navbar/open-drawer-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/url", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _url, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatNavbarOpenDrawerButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    async openDrawer() {
      this.chatStateManager.prefersDrawer();
      _url.default.routeTo(this.chatStateManager.lastKnownAppURL).then(() => {
        _url.default.routeTo(this.chatStateManager.lastKnownChatURL);
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "openDrawer", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.site.desktopView}}
          <DButton
            @icon="discourse-compress"
            @title="chat.close_full_page"
            class="c-navbar__open-drawer-button btn-transparent"
            @action={{this.openDrawer}}
          />
        {{/if}}
      
    */
    {
      "id": "EUhJVaJa",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"site\",\"desktopView\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"c-navbar__open-drawer-button btn-transparent\"]],[[\"@icon\",\"@title\",\"@action\"],[\"discourse-compress\",\"chat.close_full_page\",[30,0,[\"openDrawer\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/open-drawer-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatNavbarOpenDrawerButton;
});