define("discourse/plugins/chat/discourse/components/chat-messages-scroller", ["exports", "@ember/helper", "@ember/render-modifiers/modifiers/did-insert", "discourse/plugins/chat/discourse/modifiers/chat/scrollable-list", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _helper, _didInsert, _scrollableList, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ChatMessagesScroller = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div
      class="chat-messages-scroller popper-viewport"
      {{didInsert @onRegisterScroller}}
      {{ChatScrollableList
        (hash onScroll=@onScroll onScrollEnd=@onScrollEnd reverse=true)
      }}
    >
      {{yield}}
    </div>
  
  */
  {
    "id": "gHtejwaX",
    "block": "[[[1,\"\\n  \"],[11,0],[24,0,\"chat-messages-scroller popper-viewport\"],[4,[32,0],[[30,1]],null],[4,[32,1],[[28,[32,2],null,[[\"onScroll\",\"onScrollEnd\",\"reverse\"],[[30,2],[30,3],true]]]],null],[12],[1,\"\\n    \"],[18,4,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@onRegisterScroller\",\"@onScroll\",\"@onScrollEnd\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-messages-scroller.js",
    "scope": () => [_didInsert.default, _scrollableList.default, _helper.hash],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = ChatMessagesScroller;
});