define("discourse/plugins/chat/discourse/components/chat/list/empty-state", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EmptyState = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="c-list-empty-state" ...attributes>
      {{yield}}
    </div>
  
  */
  {
    "id": "lfDFPxRC",
    "block": "[[[1,\"\\n  \"],[11,0],[24,0,\"c-list-empty-state\"],[17,1],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/list/empty-state.js",
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = EmptyState;
});