define("discourse/plugins/chat/discourse/components/chat/modal/new-message", ["exports", "@glimmer/component", "@ember/service", "discourse/components/d-modal", "discourse/plugins/chat/discourse/components/chat/message-creator", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _dModal, _messageCreator, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatModalNewMessage extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get shouldRender() {
      return this.siteSettings.enable_public_channels || this.chat.userCanDirectMessage;
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.shouldRender}}
          <DModal
            @closeModal={{@closeModal}}
            class="chat-modal-new-message"
            @title="chat.new_message_modal.title"
            @inline={{@inline}}
            @hideHeader={{true}}
          >
            <MessageCreator @onClose={{@closeModal}} @channel={{@model}} />
          </DModal>
        {{/if}}
      
    */
    {
      "id": "Jv+PexBw",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"chat-modal-new-message\"]],[[\"@closeModal\",\"@title\",\"@inline\",\"@hideHeader\"],[[30,1],\"chat.new_message_modal.title\",[30,2],true]],[[\"default\"],[[[[1,\"\\n        \"],[8,[32,1],null,[[\"@onClose\",\"@channel\"],[[30,1],[30,3]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@closeModal\",\"@inline\",\"@model\"],false,[\"if\"]]",
      "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/modal/new-message.js",
      "scope": () => [_dModal.default, _messageCreator.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatModalNewMessage;
});