define("discourse/plugins/chat/discourse/components/chat/form", ["exports", "@glimmer/component", "discourse/plugins/chat/discourse/components/chat/form/section", "@ember/component", "@ember/template-factory"], function (_exports, _component, _section, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatForm extends _component.default {
    get yieldableArgs() {
      return {
        section: _section.default
      };
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="chat-form">
          {{yield this.yieldableArgs}}
        </div>
      
    */
    {
      "id": "5l2u723V",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-form\"],[12],[1,\"\\n      \"],[18,1,[[30,0,[\"yieldableArgs\"]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&default\"],false,[\"yield\"]]",
      "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/form.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatForm;
});