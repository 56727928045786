define("discourse/plugins/chat/discourse/components/user-threads/preview", ["exports", "@glimmer/component", "@ember/template", "discourse/helpers/format-date", "discourse/helpers/replace-emoji", "@ember/component", "@ember/template-factory"], function (_exports, _component, _template2, _formatDate, _replaceEmoji, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ThreadPreview extends _component.default {
    get lastReplyDate() {
      return (0, _formatDate.default)(this.args.preview.lastReplyCreatedAt, {
        leaveAgo: true
      });
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <span class="chat-message-thread-indicator__last-reply-timestamp">
          {{this.lastReplyDate}}
        </span>
        <span class="c-user-thread__excerpt">
          <span class="c-user-thread__excerpt-poster">
            {{@preview.lastReplyUser.username}}
          </span>
          <span>:</span>
          <span class="c-user-thread__excerpt-text">
            {{replaceEmoji (htmlSafe @preview.lastReplyExcerpt)}}
          </span>
        </span>
      
    */
    {
      "id": "J7YLsy1o",
      "block": "[[[1,\"\\n    \"],[10,1],[14,0,\"chat-message-thread-indicator__last-reply-timestamp\"],[12],[1,\"\\n      \"],[1,[30,0,[\"lastReplyDate\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,1],[14,0,\"c-user-thread__excerpt\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"c-user-thread__excerpt-poster\"],[12],[1,\"\\n        \"],[1,[30,1,[\"lastReplyUser\",\"username\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[12],[1,\":\"],[13],[1,\"\\n      \"],[10,1],[14,0,\"c-user-thread__excerpt-text\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[[28,[32,1],[[30,1,[\"lastReplyExcerpt\"]]],null]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@preview\"],false,[]]",
      "moduleName": "/build/source/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/user-threads/preview.js",
      "scope": () => [_replaceEmoji.default, _template2.htmlSafe],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ThreadPreview;
});